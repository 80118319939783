var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('SaleReportRefundTicketListFilters', {
    ref: "saleReportListFilters",
    attrs: {
      "fix-flight-type": _vm.fixFlightType,
      "init-filter-is-invoiced": _vm.initFilterIsInvoiced,
      "search-text": _vm.searchText
    },
    on: {
      "update:searchText": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "update:search-text": function updateSearchText($event) {
        _vm.searchText = $event;
      },
      "refetch-data-with-filters": _vm.refetchData,
      "refetch-data-without-filters": _vm.clearFilter
    }
  }), _c('b-card', {
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "px-0 mr-md-1",
    attrs: {
      "md": "auto"
    }
  }, [_c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "options": _vm.sizePerPageLgOptions,
      "clearable": false
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sizePerPage,
      callback: function callback($$v) {
        _vm.sizePerPage = $$v;
      },
      expression: "sizePerPage"
    }
  })], 1)])]), _c('b-overlay', {
    attrs: {
      "show": _vm.loading,
      "rounded": "sm",
      "no-fade": "",
      "variant": "transparent",
      "opacity": 0.5
    }
  }, [_c('b-table', {
    ref: "refSaleReportRefundTicketListTable",
    staticClass: "position-relative",
    staticStyle: {
      "max-height": "50vh"
    },
    attrs: {
      "sticky-header": "",
      "items": _vm.fetchRefundTicket,
      "responsive": "",
      "bordered": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "table-class": "table-sales-report",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": _vm.$t('noRecordFund'),
      "sort-desc": _vm.isSortDirDesc,
      "no-border-collapse": ""
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumns, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('div', {
            key: column.label,
            staticClass: "text-dark text-nowrap text-center"
          }, [column.key === 'checkbox' ? _c('div', {
            on: {
              "click": function click($event) {
                $event.preventDefault();
                $event.stopPropagation();
                return _vm.onHandleSelectAllOrSelectNone();
              }
            }
          }, [_c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: !_vm.showCheckboxSelectAll,
              expression: "!showCheckboxSelectAll"
            }],
            attrs: {
              "indeterminate": true,
              "inline": ""
            }
          }), _c('b-form-checkbox', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: _vm.showCheckboxSelectAll,
              expression: "showCheckboxSelectAll"
            }],
            attrs: {
              "inline": "",
              "checked": _vm.isSelectAll,
              "disabled": _vm.isAlreadySelectAll
            }
          })], 1) : [_vm._v(" " + _vm._s(_vm.$t("saleReport.columns.".concat(data.label))) + " ")]], 2)];
        }
      };
    }), _vm._l(_vm.tableColumns, function (column) {
      return {
        key: "cell(".concat(column.key, ")"),
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('div', {
            key: column.key,
            staticClass: "text-nowrap",
            class: {
              'text-right': typeof item[column.key] === 'number'
            }
          }, [_vm._v(" " + _vm._s(item[column.key]) + " ")])];
        }
      };
    }), _vm.useForInvoice ? {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          staticClass: "mr-0 ml-50 mt-50",
          attrs: {
            "name": "check-box",
            "inline": "",
            "disabled": _vm.isAlreadySelected(item),
            "checked": _vm.isAlreadySelected(item) || _vm.isChecked(item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.toggleSelectItem(item);
            }
          }
        })];
      }
    } : null, _vm.useForInvoice ? {
      key: "cell(ticketName)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.ticketTrips) + " "), _c('span', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showTicketNumber,
            expression: "showTicketNumber"
          }]
        }, [_vm._v(" " + _vm._s(item.ticketNumber || item.bookingCode) + " ")])];
      }
    } : null, {
      key: "cell(no)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.index + _vm.dataMeta.from) + " ")])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref4) {
        var _ref4$item = _ref4.item,
          bookingCode = _ref4$item.bookingCode,
          bookingId = _ref4$item.bookingId;
        return [_c('b-link', {
          staticClass: "font-weight-bolder",
          attrs: {
            "to": {
              name: 'apps-reservations-modify',
              params: {
                id: bookingId
              }
            }
          }
        }, [_vm._v(" " + _vm._s(bookingCode) + " ")])];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(_ref5) {
        var issueDate = _ref5.item.issueDate;
        return [_vm._v(" " + _vm._s(_vm.convertISODateTime(issueDate).timeDate) + " ")];
      }
    }, {
      key: "cell(bookingDate)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.bookingDate).timeDate) + " ")])];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(_ref7) {
        var priceTicket = _ref7.item.priceTicket;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(priceTicket)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref8) {
        var collectionFee = _ref8.item.collectionFee;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(collectionFee)) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tax)) + " ")])];
      }
    }, {
      key: "cell(fee)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.fee)) + " ")])];
      }
    }, {
      key: "cell(feeIn)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeIn)) + " ")])];
      }
    }, {
      key: "cell(feeOut)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeOut)) + " ")])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.feeService)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaid)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaid)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidIn)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaidIn)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidOut)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.commissionPrepaidOut)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommission)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.unpaidCommission)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.receivables)) + " ")])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(item.receivables - item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref21) {
        var totalPayment = _ref21.item.totalPayment;
        return [_c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatVnCurrency(totalPayment)) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.createdAt)) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.dateTime(data.item.updatedAt)) + " ")])];
      }
    }, {
      key: "cell(agency)",
      fn: function fn(data) {
        var _data$item$agency;
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s((_data$item$agency = data.item.agency) === null || _data$item$agency === void 0 ? void 0 : _data$item$agency.agencyCode.toUpperCase()) + " ")])];
      }
    }, _vm.useForInvoice ? {
      key: "cell(customer)",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_vm.showCustome(item) ? [_c('div', [_vm._v(" " + _vm._s(_vm.$t('invoice.customerCode')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.customer) + " ")])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('invoice.customerName')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.getCustomerName(item)) + " ")])])] : [_c('div', [_vm._v(" " + _vm._s(_vm.$t('agency.agencyCode')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.agency) + " ")])]), _c('div', [_vm._v(" " + _vm._s(_vm.$t('agency.agencyName')) + ": "), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.getAgencyName(item)) + " ")])])]];
      }
    } : null, {
      key: "cell(action)",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_c('div', {
          staticClass: "d-flex gap-1"
        }, [!item.markAsUsed ? _c('b-button', {
          staticClass: "text-nowrap p-50",
          attrs: {
            "name": "handleShowModalResell",
            "variant": "danger",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalResell(item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.sell")) + " ")]) : _vm._e(), item.markAsUsed && item.resellTicketStatus != 'SOLD' ? _c('b-button', {
          staticClass: "text-nowrap p-50",
          attrs: {
            "name": "handleShowModalResell",
            "variant": "info",
            "size": "sm"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.posted")) + " ")]) : _vm._e(), item.markAsUsed && item.resellTicketStatus === 'SOLD' ? _c('b-button', {
          staticClass: "text-nowrap p-50",
          attrs: {
            "variant": "success",
            "size": "sm"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.status.SOLD")) + " ")]) : _vm._e(), item.resellTicketId && item.markAsUsed ? _c('b-button', {
          staticClass: "text-nowrap p-50",
          attrs: {
            "name": "handleShowModalEditNote",
            "variant": "primary",
            "size": "sm"
          },
          on: {
            "click": function click($event) {
              return _vm.handleShowModalEditNote(item);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("resellTicket.btn.editNote")) + " ")]) : _vm._e()], 1)];
      }
    }], null, true)
  }), _vm.useForInvoice ? _c('div', {
    staticClass: "d-flex justify-content-end align-items-center"
  }, [_c('div', [_c('div', [_c('b-form-checkbox', {
    attrs: {
      "inline": "",
      "checked": _vm.isCombineTickets,
      "disabled": _vm.selectionCount < 2
    },
    on: {
      "change": function change($event) {
        _vm.isCombineTickets = !_vm.isCombineTickets;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.combineTickets')) + " ")])], 1), _c('div', [_c('b-form-checkbox', {
    attrs: {
      "inline": "",
      "checked": _vm.showTicketNumber,
      "disabled": _vm.isCombineTickets
    },
    on: {
      "change": function change($event) {
        _vm.showTicketNumber = !_vm.showTicketNumber;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.showTicketNumber')) + " ")])], 1)])]) : _vm._e()], 1), _c('div', {
    staticClass: "mx-2 mb-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.dataMeta.from) + " " + _vm._s(_vm.$t('paginationText.to')) + " " + _vm._s(_vm.dataMeta.to) + " " + _vm._s(_vm.$t('paginationText.outOf')) + " " + _vm._s(_vm.dataMeta.of) + " " + _vm._s(_vm.$t('paginationText.items')) + " ")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.totalItems,
      "per-page": _vm.sizePerPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('ResellTicketModal', {
    attrs: {
      "sell-ticket": _vm.sellTicket
    },
    on: {
      "refresh": _vm.refetchData
    }
  }), _c('NoteModal', {
    attrs: {
      "ticket": _vm.modifyNote
    },
    on: {
      "update:ticket": function updateTicket($event) {
        _vm.modifyNote = $event;
      },
      "refetch-data": _vm.refetchData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }