<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- ANCHOR Button Search -->
        <b-button
          variant="warning"
          class="mr-1"
          @click="onUpdateFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('search') }}
          </span>
        </b-button>

        <!-- ANCHOR Button Clear Search Filters -->
        <b-button
          variant="danger"
          @click="onClearFilters"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row>
        <!-- ANCHOR START DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('topup.startDate')"
          >
            <b-form-datepicker
              v-model="startDate"
              style="font-size: 1rem;"
              :today-button="!endDate"
              reset-button
              close-button
              :locale="$i18n.locale"
              :max="maxStartDate"
              :placeholder="$t('topup.placeholderSelectDate')"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            />
          </b-form-group>
        </b-col>

        <!-- ANCHOR END DATE -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label-class="h5 py-0"
            :label="$t('topup.endDate')"
          >
            <b-form-datepicker
              v-model="endDate"
              style="font-size: 1rem;"
              today-button
              reset-button
              close-button
              :locale="$i18n.locale"
              :min="minEndDate"
              :max="today"
              :placeholder="$t('topup.placeholderSelectDate')"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <!-- ANCHOR AGENCY CODE -->
          <b-form-group
            label-class="h5 py-0"
            :label="$t('agency.agencyCode')"
          >
            <b-form-input
              v-model.trim="agencyCode"
              :placeholder="$t('saleReport.phAgencyCode')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <!-- ANCHOR FLIGHT TYPE -->
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.flightType')"
          >
            <v-select
              v-model="flightType"
              class="w-100"
              style="font-size: 1rem;"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="flightTypeOptions"
              label="label"
              :clearable="false"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              :disabled="Boolean(fixFlightType)"
              @input="val => $emit('update:flightTypeFilter', val)"
            >
              <template #option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(data.label) }}
                </span>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <!-- ANCHOR BOOKING CODE -->
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.bookingCode')"
          >
            <b-form-input
              v-model.trim="bookingCode"
              :placeholder="$t('saleReport.phBookingCode')"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <!-- ANCHOR Ticket number -->
          <b-form-group
            label-class="h5 py-0"
            :label="$t('saleReport.columns.ticketNumber')"
          >
            <b-form-input
              v-model.trim="ticketNumber"
              :placeholder="$t('saleReport.phTicketNumber')"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import {
  flightTypeOptions,
  attachmentStatusOptions,
} from '@/constants/saleReport'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormDatepicker,
    BFormGroup,
    BFormInput,
    vSelect,
  },
  props: {
    searchText: {
      type: String,
      default: '',
    },
    fixFlightType: {
      type: String,
      default: '',
    },
    initFilterIsInvoiced: {
      type: [Boolean, String],
      default: '',
    },
  },

  watch: {
    fixFlightType: {
      handler(val) {
        this.flightType = val
      },
      immediate: true,
    },
  },

  setup(props, { emit }) {
    const startDate = ref('')
    const endDate = ref('')
    const agencyCode = ref('')
    const flightType = ref('')
    const bookingCode = ref('')
    const ticketNumber = ref('')

    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())

    const maxStartDate = computed(() => (endDate.value ? endDate.value : today))
    const minEndDate = computed(() => (startDate.value ? startDate.value : undefined))

    const onClearFilters = () => {
      startDate.value = ''
      endDate.value = ''
      agencyCode.value = ''
      flightType.value = ''
      bookingCode.value = ''
      ticketNumber.value = ''
      // emit('update:searchText', '')
      emit('refetch-data-without-filters')
    }

    const onUpdateFilters = () => {
      const filtersData = {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        agencyCode: agencyCode.value || undefined,
        flightType: flightType.value || undefined,
        bookingCode: bookingCode.value || undefined,
        ticketNumber: ticketNumber.value || undefined,
      }
      emit('refetch-data-with-filters', filtersData)
    }

    return {
      // select Options
      flightTypeOptions,
      attachmentStatusOptions,

      startDate,
      endDate,
      agencyCode,
      flightType,
      bookingCode,
      ticketNumber,

      today,
      maxStartDate,
      minEndDate,

      onClearFilters,
      onUpdateFilters,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}
</style>
